import { graphql } from "gatsby";
import * as React from "react";

import { default as TagPage, Props } from "../components/TagPage";

const TagRoute: React.SFC<Props> = ({ data, pageContext }) => <TagPage data={data} pageContext={pageContext} />;

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___published], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            lang
            description
            templateKey
            published(formatString: "MMMM DD, YYYY")
            publishedDate: published(formatString: "YYYY-MM-DD")
            tags
          }
        }
      }
    }
  }
`;
