import { Link} from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import PostPreview from "./PostPreview";

export interface Props {
  pageContext: {
    tag: string;
  };
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: {
          id: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            title: string;
            author: string;
            description: string;
            lang: string;
            templateKey: string;
            published: string;
            publishedDate: string;
            tags: string[];
          };
        };
      }>;
    };
  };
}

const TagPage: React.SFC<Props> = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges.map(p => <PostPreview key={p.node.id} post={p.node} />);
  const tag = pageContext.tag;
  const totalCount = data.allMarkdownRemark.totalCount;
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"} tagged with “${tag}”`;

  return (
    <Layout>
      <Helmet title={`${tag} - ${data.site.siteMetadata.title}`} />
      <div className="hero is-dark is-bold">
        <div className="hero-body">
          <div className="container is-narrow">
            <h1 className="title">{tagHeader}</h1>
            <Link to="/tags/" className="button is-info">Browse all tags</Link>
          </div>
        </div>
      </div>
      <div className="container is-narrow">
        <section className="section">
          <ul className="post-list">{posts}</ul>
        </section>
      </div>
    </Layout>
  );
};

export default TagPage;
